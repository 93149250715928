var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "添加管理员",
      visible: _vm.addAuthorityVisible,
      width: "400px",
      center: "",
      "before-close": _vm.handleCloseFn,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.addAuthorityVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "add-authority-con"
  }, [_c("el-form", {
    ref: "formValidate",
    attrs: {
      model: _vm.formValidate,
      rules: _vm.ruleValidate,
      "label-width": "90px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入姓名",
      clearable: ""
    },
    model: {
      value: _vm.formValidate.name,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formValidate.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "mobile"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "11",
      placeholder: "请输入手机号码",
      oninput: "value=value.replace(/[^\\d]/g,'')",
      clearable: ""
    },
    model: {
      value: _vm.formValidate.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "mobile", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formValidate.mobile"
    }
  })], 1), _c("el-form-item", {
    staticClass: "footer-btn"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        return _vm.handleCancel("formValidate");
      }
    }
  }, [_vm._v("取消 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.handleSubmit("formValidate");
      }
    }
  }, [_vm._v("确定 ")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };