import "core-js/modules/es.array.push.js";
import config from '@/config';
import Pagination from '@/components/pagination/Pagination';
import AddAuthority from '@/components/payslipAuthority/AddAuthority';
import { userGetAdminUserList, userRemoveAdminAuth, getRoles } from '@/api/salary';
export default {
  name: 'PayslipAuthority',
  components: {
    Pagination,
    AddAuthority
  },
  data() {
    return {
      addAuthorityVisible: false,
      // 新增选项
      formInline: {
        name: '',
        mobile: ''
      },
      tableData: [],
      roleList: [],
      // 角色列表
      pages: {
        currentPage: 1,
        // 当前页码
        pageTotal: 0,
        // 数据总条目
        pageSize: 50 // 每页显示多少条数据
      },
      adminRoleId: '',
      // 管理员角色id
      userRoleId: '' // 普通用户角色id
    };
  },
  mounted() {
    this.getRolesFn();
  },
  methods: {
    indexMethod(index) {
      const {
        currentPage,
        pageSize
      } = this.pages;
      return index + 1 + (currentPage - 1) * pageSize;
    },
    // 获取角色列表
    async getRolesFn() {
      const res = await getRoles();
      if (res !== null && res !== void 0 && res.data) {
        this.roleList = res.data || [];
        this.adminRoleId = this.roleList.find(item => item.roleName === config.ADMIN).id;
        this.userRoleId = this.roleList.find(item => item.roleName === config.USER).id;
        this.fetchDataFn(this.pages);
      }
    },
    // 请求管理员分页数据
    async fetchDataFn(pages) {
      const {
        currentPage: pageNo,
        pageSize
      } = pages;
      const params = {
        pageNo,
        pageSize,
        ...this.formInline,
        roleId: this.adminRoleId
      };
      const res = await userGetAdminUserList(params);
      if (res !== null && res !== void 0 && res.data) {
        const {
          items = [],
          total = '0'
        } = res.data;
        this.tableData = items;
        this.pages.pageTotal = Number(total);
      }
    },
    // 分页改变
    handleChangePageFn(val) {
      this.$refs.tableRef.bodyWrapper.scrollTop = 0;
      this.pages = {
        ...this.pages,
        ...val
      };
      this.fetchDataFn(this.pages);
    },
    handleSearch() {
      this.pages.currentPage = 1;
      this.fetchDataFn(this.pages);
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.resetPagination();
      this.fetchDataFn(this.pages);
    },
    // 重置分页
    resetPagination() {
      this.pages = {
        ...this.pages,
        currentPage: 1,
        pageSize: 50
      };
    },
    goPageFn(path) {
      this.$router.push(path);
    },
    // 新增管理员
    addAuthorityFn() {
      this.addAuthorityVisible = true;
    },
    addSuccessFn() {
      this.fetchDataFn(this.pages);
    },
    // 删除管理员
    handleDelAuthorityFn(row) {
      this.$confirm('您确认将删除该用户的管理员权限吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRecordFn(row);
      }).catch(() => {});
    },
    async deleteRecordFn(row) {
      const params = {
        id: row.id,
        name: row.name,
        mobile: row.mobile,
        roleId: this.userRoleId
      };
      const res = await userRemoveAdminAuth(params);
      if (res !== null && res !== void 0 && res.data) {
        const msg = this.$message({
          message: '操作成功',
          type: 'success'
        });
        setTimeout(() => {
          msg.close();
          this.fetchDataFn(this.pages);
        }, 800);
      }
    }
  }
};