import { userInsertAdminUser } from '@/api/salary';
export default {
  name: 'AddAuthority',
  props: {
    addAuthorityVisible: {
      type: Boolean,
      default: false
    },
    adminRoleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formValidate: {
        name: '',
        mobile: ''
      },
      ruleValidate: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 25,
          message: '长度在 1 到 25 个字符',
          trigger: ['blur', 'change']
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: ['blur', 'change']
        }]
      }
    };
  },
  methods: {
    // 提交
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          const config = {
            loadingConfig: {
              target: '.el-dialog'
            }
          };
          const params = {
            roleId: this.adminRoleId,
            ...this.formValidate
          };
          const res = await userInsertAdminUser(params, config);
          if (res !== null && res !== void 0 && res.data) {
            const msg = this.$message({
              message: '添加成功',
              type: 'success'
            });
            setTimeout(() => {
              msg.close();
              this.$emit('update:addAuthorityVisible', false);
              this.$emit('addSuccess');
            }, 500);
          }
        }
      });
    },
    handleCancel(name) {
      this.$refs[name].resetFields();
      this.$emit('update:addAuthorityVisible', false);
    },
    handleCloseFn() {
      this.$emit('update:addAuthorityVisible', false);
    }
  }
};