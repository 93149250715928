import { render, staticRenderFns } from "./PayslipAuthority.vue?vue&type=template&id=49252cbc&scoped=true"
import script from "./PayslipAuthority.vue?vue&type=script&lang=js"
export * from "./PayslipAuthority.vue?vue&type=script&lang=js"
import style0 from "./PayslipAuthority.vue?vue&type=style&index=0&id=49252cbc&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49252cbc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/cci-14816-341419/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49252cbc')) {
      api.createRecord('49252cbc', component.options)
    } else {
      api.reload('49252cbc', component.options)
    }
    module.hot.accept("./PayslipAuthority.vue?vue&type=template&id=49252cbc&scoped=true", function () {
      api.rerender('49252cbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/payslip/PayslipAuthority.vue"
export default component.exports